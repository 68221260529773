<template>
    <div class="page">
        <div class="overlay"></div>
        <img class="notice" src="@/assets/openwithbrower.png" alt="" v-if="isWeixin">
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import CallApp from 'callapp-lib'

const isWeixin = ref(true)

const startGame = () => {
    // 获取设备信息
    var ua = navigator.userAgent.toLowerCase();
    isWeixin.value = ua.indexOf('micromessenger') != -1;
    if (!isWeixin.value) {
        const options = {
            scheme: {
                protocol: 'racoondigi'//app名字
            },
            appstore: 'https://apps.apple.com/zh/app/id6443768967',//app商城地址
            fallback: 'https://jqys.cn',
            timeout: 5000
        }
        const callLib = new CallApp(options);
        callLib.open({
            path: ''   //后端给的地址
        })
        // var iframe = document.createElement('iframe');
        // var body = document.body;
        // iframe.style.cssText='display:none;width=0;height=0';
        // var timer = null;
        // // 立即打开的按钮
        // // var openapp = document.getElementById('openapp');
        // // openapp.addEventListener('click', function() {
        // iframe.src = "racoondigi://";
        // body.appendChild(iframe);
        // console.log(iframe)
        // timer = setTimeout(function() {
        //     window.location.href = "https://jqys.cn";
        // }, 500);
    }

    
}

onMounted(()=>{
    startGame()
})
</script>
<style src="./StartGame.scss" lang='scss' scoped></style>