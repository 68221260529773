import md5 from 'js-md5';
import { getItem, setItem } from './storage';
import { csvCommonRequest } from '@/config/api';

const getMedia = (time,urlPath) => {
  let baseUrl = "https://devres.racoondigi.cn/"
  let authkey = 'waGj3j9CbK79sN3HUfDNMt'
  // let md5Str = md5.hex(authkey+time+urlPath)
  let md5Str = md5(authkey+time+urlPath)
  let result = baseUrl+time+"/"+md5Str+urlPath
  
  return result
}

function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}

function formatTimeTwo(format) {
   
    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];
  
    // var date = new Date(number * 1000);
    var date = new Date(Date.parse(new Date())+86400000);
    returnArr.push(date.getFullYear());
    returnArr.push(formatNumber(date.getMonth() + 1));
    returnArr.push(formatNumber(date.getDate()));
  
    returnArr.push(formatNumber(date.getHours()));
    returnArr.push(formatNumber(date.getMinutes()));
    returnArr.push(formatNumber(date.getSeconds()));
  
    for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
}

// 更新过期csv缓存
async function checkCsv() {
    let csvListRequired = ["avatar.csv","character.csv","clothing.csv","items.csv","character.csv","frames.csv","exclusive_motion.csv"]
    let csvListNeed = []
    for ( var item of csvListRequired ) {
        if (!getItem(item)) {
            csvListNeed.push(item)
        }
    }
    if ( csvListNeed.length != 0 ) {
        await csvCommonRequest("index/csvInfo",{
            "csv_names":JSON.stringify(csvListNeed)
        },"GET").then(res=>{
            console.log(res)
            var keys = Object.keys(res.data)
            for (var key of keys) {
                setItem(key,csvTrans(res.data[key]),300)
            }
        })
    }
}

//处理接口请求的csv二位数组
function csvTrans (csv)  {
    let tmp = {}
    for ( var i = 1; i < csv.length; i++  ) {
        let tmp2 = {}
        for ( var j = 1; j < csv[0].length; j++ ) {
            try {
                tmp2[csv[0][j]] = JSON.parse(csv[i][j])
            } catch (e) {
                tmp2[csv[0][j]] = csv[i][j]
            }
        }
        tmp[csv[i][0]] = tmp2
    }
    return JSON.stringify(tmp)
}

const errorImg = (e) => {
    // console.log(e.target.src,"法1")
    // console.log(e.srcElement.src,"法2")
    e.srcElement.src = require('../assets/avatar-error.jpg');
    //这一句没用，如果默认图片的路径错了还是会一直闪屏，在方法的前面加个.once只让它执行一次也没用
    e.srcElement.onerror = null; //防止闪图
}

export default {
    getMedia,
    formatTimeTwo,
    checkCsv,
    errorImg
}