import axios from "axios";
import { getItem, removeItem } from "./storage"
import server from "../config/config"

const request = axios.create({
    baseURL: server.serverIp,
    headers:{ //请求头
        'Content-Type': 'application/json'
        // 'X-Hioshop-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI1IiwiaWF0IjoxNjg0MTE1NDg1LCJpc3MiOiJ0ZXN0In0.HwrsA8RbvwuCdxpdwvLDaejMVnrkQ7snDY7vMAxJpLY'
    },
})

// 响应拦截器
request.interceptors.response.use(
    response => {
        // 这里拦截401错误，并重新跳入登页重新获取token
        if (response.status && response.status === 200) {
          // 通讯成功
          if (response.data.errno === 0) {
            return response
          } else if (response.data.code === 401 ) { 
            // 如果是token过期，跳转至登录
            alert("登录已过期，请重新登录！")
            // this.$message.error("登录已过期，请重新登录！");
            removeItem("token")
            removeItem("player")
            location.href = server.serverIp
          }
          return Promise.resolve(response)
        }
    },
)
request.interceptors.request.use(
    config => {
        if ( getItem("token") ) {
            config.headers["token"] = getItem("token")
        }
        return config
    }
)

const csvRequest = axios.create({
    baseURL: server.csvServerIp,
    headers:{ //请求头
        'Content-Type': 'application/json'
    },
})

export {
    request,
    csvRequest
}