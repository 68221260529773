<template>
    <Overlay :show="show" :z-index="100">
        <div class="wrapper" @click.stop>
            <div :class="type=='rule'?'ruleTip':'tip'">
              <img class="tip-title" src="@/assets/rule-title.png" alt="" v-if="type=='rule'">
              <img class="tip-title" src="@/assets/ended-title.png" alt="" v-else-if="type=='ended'">
              <img class="tip-title" src="@/assets/tip-title.png" alt="" v-else>
              <img class="tip-close" src="@/assets/tip-close.png" alt="" @click="close" v-if="type!='ended'">
              <img class="tip-sub" src="@/assets/btn-sub.png" alt="" @click="close" v-if="showBtn&&type == 'tip'">
              <img class="tip-sub" src="@/assets/copyLink.png" alt="" v-if="showBtn&&type=='copylink'" @click="handleCopy">
              <div class="tip-tipRule" v-if="type=='rule'">
                <div><text class="tip-tipRule-index">①</text><text class="tip-tipRule-con">活动时间：即日起至12月17日23点59分59秒</text></div>
                <div><text class="tip-tipRule-index">②</text><text class="tip-tipRule-con">本活动页内完成系列任务可获得抽奖次数。</text></div>
                <div><text class="tip-tipRule-index">③</text><text class="tip-tipRule-con">抽奖次数可通过完成任务、邀请好友获得，消耗抽奖次数进行活动抽奖赢取奖励。</text></div>
                <div><text class="tip-tipRule-index">④</text><text class="tip-tipRule-con">分享活动给微信好友，好友登录并绑定账号后双方皆可获得抽奖次数；邀请发起方可额外获得1次抽奖次数；至多邀请10位好友。</text></div>
                <div><text class="tip-tipRule-con">PS：</text></div>
                <div><text class="tip-tipRule-index">①</text><text class="tip-tipRule-con">道具奖励在获奖后24小时内发放至当前活动绑定的游戏账号内，请在游戏内邮箱查收。</text></div>
                <div><text class="tip-tipRule-index">②</text><text class="tip-tipRule-con">如通过不正当方式参与活动，官方有权取消违规用户参与资格与获奖资格。</text></div>
              </div>
              <div id="link" class="tip-tipContent" v-else>
                <div>{{ content }}</div>
                <div>{{ content2 }}</div>
                <div v-if="type=='share'">未安装游戏，请前往下载</div>
                <a v-if="type=='share'" href="https://jqys.cn">前往下载</a>
              </div>
            </div>
        </div>
    </Overlay>
</template>
<script setup>
import { ref } from 'vue';
import { Overlay, showToast } from 'vant';
import useClipboard from 'vue-clipboard3'

const props = defineProps({
  content: {
    default: ""
  },
  content2: {
    default: ""
  },
  linkContent: {
    default: ""
  },
  show: {
    default: true
  },
  showBtn: {
    default: false
  },
  type: {
    default: "rule"
  }
})

const { toClipboard } = useClipboard()
const link = ref()
const ctx = defineEmits(["close"])
const close = () => {
  ctx("close")
}

// const handleCopy = () => {
//   var copyDOM = document.querySelector('#link');  
//   var range = document.createRange();  
//   // 选中需要复制的节点
//   range.selectNode(copyDOM);
//   // 执行选中元素
//   window.getSelection().addRange(range);
//   // 执行 copy 操作
// var successful = document.execCommand('copy');  
//   try {  
//     var msg = successful ? 'successful' : 'unsuccessful';  
//     // console.log('copy is' + msg);
//     if (msg=="successful") {
//       showToast({
//         message: '复制成功',
//         position: 'top',
//       });
//     }
//   } catch(err) {  
//     console.log('Oops, unable to copy');  
//   }
// // 移除选中的元素
//   window.getSelection().removeAllRanges();  
// }

const handleCopy = async() => {
  try {
    // 复制
    // console.log(window.location)
    await toClipboard("《街球艺术》邀你围观首届大师赛，赛季球员礼包，888元红包免费拿。赶紧行动吧！"+props.linkContent)
      showToast({
        message: '复制成功',
        position: 'top',
      });
    // 复制成功
  } catch (e) {
    // 复制失败
  }
}

</script>
<style src="./TipBox.scss" lang='scss' scoped></style>