<template>
  <div class="page">
    <div class="banner">
      <img class="rule-btn" src="@/assets/btn-rule.png" alt="" @click="showRule">
      <img class="logout-btn" src="@/assets/btn-logout.png" alt="" @click="logout" v-if="islogined">
      <img class="banner-img" src="@/assets/banner.jpeg" alt="">
    </div>
    <div v-if="!islogined">
      <div class="input-container">
          <div class="input-text">
              <input class="input-content" v-model="mobilephone" @input="onCardNameChange" type="number"
                  placeholder="请输入手机号码登录" />
          </div>
          <div class="sms-button" v-if="!sendsms" @click="sendSms">
              <div class="sms-content">获取验证码</div>
          </div>
          <div class="sms-button" v-else>
              <CountDown :time="time" format="ss" @finish="resetSend">
                  <template #default="timeData">
                      <span class="block">{{ timeData.seconds }}s</span>
                  </template>
              </CountDown>
          </div>
      </div>
      <div class="input-container">
          <div class="input-text">
              <input class="input-content" v-model="sms" @input="onCardNameChange" type="number"
                  placeholder="请输入短信验证码" />
          </div>
          <div class="login-button" @click="login">
              <div class="login-content">登录</div>
          </div>
      </div>
    </div>
    <div v-else>
      <div class="input-container">
        <div class="input-text">
          <div class="nickname-content" v-if="mineInfo.nickname==''">暂未绑定游戏内账号，请前往游戏内绑定</div>
          <div class="nickname-content" v-else>已绑定角色：<text class="nickname-name">{{ mineInfo.nickname }}</text></div>
          <div class="login-button">
              <div class="login-content" @click="getInfo('refresh')">刷新</div>
          </div>
        </div>
      </div>
    </div>
    <div class="lottery">
      <div class="lottery-title1">每日惊喜抽奖</div>
      <p class="lottery-title2">每日参与抽奖即有机会获得<br>丰厚的游戏道具等豪礼哦！</p>
      <NoticeBar class="notice" background="#E9DCF6" color="#666666" :scrollable="false" v-if="noticeShow">
        <Swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :touchable="false"
          :show-indicators="false"
        >
          <SwipeItem v-for="item in noticeList">{{ item.content }}</SwipeItem>
        </Swipe>
      </NoticeBar>
      <Lottery :loading="loading" :DrawList="DrawList" :lotteryChance="lotteryChance" :choosedImg="choosedImg" :unchoosedImg="unchoosedImg" :prizeIndex="prizeIndex" @goDraw="goDraw"></Lottery>
    </div>
    <div class="mission">
      <img class="mission-title" src="@/assets/mission-title.png" alt="">
      <div class="mission-list">
        <div v-for="item in missionList" :key="item">
          <div class="mission-box" v-if="item!=undefined">
            <div class="mission-each-title">
              <div class="mission-each-title-1">{{ item.title }}</div>
              <div class="mission-each-title-2">{{ item.desc }}</div>
            </div>
            <img class="mission-btn-uncompleted" src="@/assets/btn-completed.png" alt="" v-if="item.completed&&item.awarded">
            <img class="mission-btn-uncompleted" src="@/assets/btn-gain.png" alt="" v-else-if="item.completed&&!item.awarded" @click="goGain(item)">
            <img class="mission-btn-completed" src="@/assets/btn-uncompleted.png" alt="" v-else @click="goCompleteMission(item)">
          </div>
        </div>
      </div>
    </div>
    <div class="invitation">
      <img class="invitation-title" src="@/assets/invitation-title.png" alt="">
      <div class="invitation-content">
        <div class="invitation-desc1">邀请好友参加活动</div>
        <div class="invitation-desc2">建立邀请关系后，邀请与被邀请人均获得1次抽奖机会。邀请人每次邀请成功额外获得1次抽奖机会</div>
        <div class="invitation-list-title">我的邀请</div>
        <div class="invitation-list">
          <div class="invitation-each" v-for="item in invitationList" :key="item">
              <img class="invitation-list-btn" :src=item.url alt="" @error="utils.errorImg($event)">
              <div class="invitation-list-desc">抽奖券*2</div>
          </div>
        </div>
        <div class="share" @click="share">分享给好友</div>
      </div>
    </div>
    <div class="placeholder"></div>
    <Tip :show="tipShow" :content="tipContent" :linkContent="tipLink" @close="tipShow=!tipShow" :type="tipType" :showBtn=true></Tip>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { CountDown, showToast, NoticeBar, Swipe,  SwipeItem } from 'vant';
import Lottery from '../components/LotteryBox.vue'
import Tip from '../components/TipBox.vue'
import utils from '../utils/utils';
import { commonRequest } from "../config/api"
import { getItem, removeItem, setItem } from '@/utils/storage';

const router = useRouter()

//获取十分钟之后的年月日十分
let timestamp = utils.formatTimeTwo('YMDhm')
const mobilephone = ref("")
const sms = ref("")
const time = ref(60 * 1000)
const sendsms = ref(false)
const mineInfo = ref()
const prizeIndex = ref(0) // 中奖序号
const tipType = ref("copylink") // 提示弹窗类型
const tipContent = ref() // 弹窗内容
const tipLink = ref() // 分享链接地址
const islogined = ref(false)
const DrawList = ref() // 奖品内容
const choosedImg = ref(require("@/assets/prize-choosed.png")) // 中奖item背景图
const unchoosedImg = ref(require("@/assets/prize.png")) // 中奖item背景图
const btnStart = { prizeImg: '', desc: '开始抽奖' }
const lotteryChance = ref(0) // 剩余中奖次数

const missionList = ref() // 任务列表
const follow = ref(false) // 关注系列任务是否完成

const tipShow = ref(false)
const noticeShow = ref(false) // 跑马灯是否展示
const noticeList = ref([]) // 跑马灯列表
const loading = ref(true) // 页面数据载入完毕

// 邀请人列表
const invitationList = ref([
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")},
  { url: require("@/assets/btn-invite.png")}
])

// 获取基本数据
const getInfo = (event) => {
  getLocal()
  let data = {}
  if (event=='refresh') {
    data.type = 'refresh'
  }
  commonRequest("campaign/index", data, "GET").then((res) => {
    const items = getItem("items.csv")
    if (res.code === 0) {
      // console.log(res.data)
      const { campaigns, rewards, ended } = res.data
      if (ended) {
        tipType.value = "ended"
        tipContent.value = "本次活动已于2023年12月17日23点59分59秒结束"
        tipShow.value = !tipShow.value
      }
      // console.log(JSON.stringify(getItem('avatar.csv')))
       const pirzeList =  rewards.map((ele)=>{
        let result = {prizeImg: ""}
        switch (ele.category) {
          case 1:
            if (ele.type==1) {
              result["prizeImg"] = utils.getMedia(timestamp,"/hammer/jqys_v3/icons/ui_reward_icon_diamond.png")
              result["desc"] = "钻石"
            }
            else if (ele.type==2) {
              result["prizeImg"] = utils.getMedia(timestamp,"/hammer/jqys_v3/icons/ui_reward_icon_goldcoins.png")
              result["desc"] = "金币"
            }
            else if (ele.type==3) {
              result["prizeImg"] = utils.getMedia(timestamp,"/hammer/jqys_v3/icons/ui_reward_icon_energy.png")
              result["desc"] = "能量胶"
            }
            else if (ele.type==5) {
              result["prizeImg"] = utils.getMedia(timestamp,"/hammer/jqys_v3/icons/ui_reward_icon_gem.png")
              result["desc"] = "宝石"
            }
            break;
          case 4:
            result["prizeImg"] = utils.getMedia(timestamp,"/hammer/jqys_v3/icons/item_"+ele.type+".png")
            result["desc"] = items[ele.type].name
          break;
          default:
            break;
        }
        result["num"] = ele.num
        return result
      })
      DrawList.value = computed(() => {
        return [...pirzeList.slice(0, 4), btnStart, ...pirzeList.slice(4)]
      })

      if (Reflect.has(res.data,"player")) {
        // 检查是否完成关注系列任务
        if (res.data.player.campaign_data[1].awarded&&res.data.player.campaign_data[2].awarded&&res.data.player.campaign_data[3].awarded) {
          follow.value = true
        }
      }
      missionList.value = campaigns.map((ele,index)=>{
        var result = {id:ele.id,type:ele.type,url:ele.ext,title:ele.name,desc:ele.desc,completed: false,awarded:false}
        if (Reflect.has(res.data,"player")) {
          // console.log(res.data.player.campaign_data[index]>ele.value)
          if (res.data.player.campaign_data[index].value >= ele.value) {
            result.completed = true
          }
          result.awarded = res.data.player.campaign_data[index].awarded
        }
        if (follow.value && ele.type!='follow') {
          return result
        }
        else if (!follow.value && ele.type!='watch') {
          return result
        }
        // else {return result}
        // return result
      })
      // console.log(missionList.value)
      
      // 是否有个人信息
      if (Reflect.has(res.data,"player")) {
        // 更新可抽奖次数
        lotteryChance.value = res.data.player.award_times
        // 如果没完成每日签到任务则请求完成任务接口完成任务
        if (res.data.player.campaign_data[0].value<1) {
          // console.log("没完成每日签到，现在完成！")
          commonRequest("campaign/award",{id:"signin"},"GET").then((res)=>{
            // console.log(res)
            if (res.code==0) {
              missionList.value[0].completed = true
              missionList.value[0].awarded = true
              lotteryChance.value += 1
            }
          })  
        }
        // 更新邀请人列表信息
        setItem("player", JSON.stringify(res.data.player))
        if (Reflect.has(res.data.player,"invitee_info_list")) {
          res.data.player.invitee_info_list.map((ele,index)=>{
            invitationList.value[index] = {url:"https://jqys-files.racoondigi.cn/ugc_pics/" + ele.portrait.split(":")[0].split(",")[0]}
          })  
        }
        
      }

      // 跑马灯相关
      if (Reflect.has(res.data,"reward_history")) {
        noticeList.value = res.data.reward_history
        noticeShow.value = true
      }
      // ref([
      //   {title:"每天访问该页面", desc:"每天打开活动就可获得抽奖券*1",completed:true},
      //   {title:"关注街球艺术微信公众号", desc:"每天打开活动就可获得抽奖券*1",completed:false},
      // ])
      loading.value = false
      if (event=='refresh') {
        showToast({
          message: res.msg
        })
      }
    } else if (res.code === 1001 ) {
      // console.log(res.data)
    } 
    else {
    }
  })
}

// 获取本地登录信息
const getLocal = async() => {
    mineInfo.value = getItem("player")
    if (mineInfo.value != null) {
      islogined.value = true
    }
    else {
      islogined.value = false
    }
}

// 发送短信验证码
const sendSms = () => {
  if (mobilephone.value=="") {
    return
  }
  commonRequest("campaign/auth_code", { mobile: mobilephone.value.toString() }, "POST").then((res) => {
    if (res.code == 0) {
      sendsms.value = true
      showToast({
        message: "发送成功"
      })
    } else {
      showToast({
        message: res.msg
      })
    }
  })
}

// 重新允许发送验证码
const resetSend = () => {
    sendsms.value = false
}

// 登录
const login = () => {
  var matchReg = /invite_code=.{24}/gi
  if (mobilephone.value==""||sms.value=="") {
    return
  }
  var data = {
    mobile: mobilephone.value.toString(),
    auth_code: sms.value.toString()
  }
  // 如果url中包含邀请码则登录时附带邀请码
  if (Reflect.has(router.currentRoute.value.query,"invite_code")) {
    // console.log(router.currentRoute.value.query)
    // data["invite_code"] = router.currentRoute.value.query.invite_code
    data["invite_code"] = router.currentRoute.value.href.match(matchReg)[0].substr(-24)
  }
  commonRequest("campaign/login", data, "POST").then((res) => {
    if (res.code === 0) {
      setItem("player", JSON.stringify(res.data.player))
      mobilephone.value = ""
      sms.value = ""
      getInfo()
      // location.reload()
    } else if (res.code === 1001 ) {
      setItem("player", JSON.stringify({nickname:null}))
      mobilephone.value = ""
      sms.value = ""
      getInfo()
      // location.reload()
    } 
    else {
      showToast({
        message: res.msg
      })
    }
  })
}

// 退出登录
const logout = () => {
  removeItem("token")
  removeItem("player")
  sendsms.value = false
  getInfo()
}



// 请求抽奖接口
const goDraw = (callback) => {
  if (!islogined.value) {
    showToast({
      message: "请先登录"
    })
    return
  }
  else if (lotteryChance.value == 0) {
    showToast({
      message: "抽奖次数不足"
    })
    return
  }
  if (lotteryChance.value>0) {
    commonRequest("campaign/lottery",{},"GET").then((res)=>{
      // console.log(res.data)
      if (res.code==0) {
        let result = {index:res.data.awarded_index,code:0,msg:res.msg}
        // lotteryChance 
        callback(result)
        lotteryChance.value -= 1
      }
      else {
        callback({index:null,code:1000,msg:res.msg})
      }
    })
  }
}

// 打开活动规则组件
const showRule = () => {
  tipType.value = "rule"
  tipShow.value = !tipShow.value
}

// 跳转唤醒app页面
const goCompleteMission = (event) => {
  if (!islogined.value) {
    showToast({
      message: "请先登录"
    })
    return
  }
  // console.log(event)
  // 获取设备信息
  var ua = navigator.userAgent.toLowerCase();
  // console.log(ua)
  var isAndroid = ua.indexOf('android') != -1;
  var isiPhone = ua.indexOf('iphone') != -1;
  var isIpad = ua.indexOf('ipad') != -1;
  var isMobile = isAndroid || isiPhone || isIpad
  if (!isMobile) {
    // console.log(111)
    showToast({
      message: "请在移动端访问"
    })
    return
  }
  if (event.type=="follow"||event.type=="watch") {
    // console.log("跳转关注或登录")
    // 请求完成任务接口
    commonRequest("campaign/finish",{id:event.id},"GET").then((res)=>{
      // console.log(res)
      if (res.code==0) {
        if (isiPhone) {
          window.location.href = event.url
          setTimeout(getInfo,1500)
        }
        else {
            window.location.href = event.url
            setTimeout(()=>{getInfo()},1500)
            
          // window.location.href = event.url
          // setTimeout(function (event=event) {
          //   missionList.value = missionList.value.map((ele)=>{
          //     if (ele.id == event.id) {
          //       ele.completed = true
          //     }
          //     return ele
          //   })
          // },1500)
        }
      }
    })  
  }
  else if (!isiPhone && !isIpad) {
    tipShow.value = true
    tipType.value = 'share'
    tipContent.value = "请前往游戏内完成"
  }
  else {
    router.push({name: "startgame"})
  }
    // const options = {
    //     scheme: {
    //         protocol: 'racoondigi'//app名字
    //     },
    //     appstore: 'https://apps.apple.com/zh/app/id6443768967',//app商城地址
    // }
    // const callLib = new CallApp(options);
    // callLib.open({
    //     path: 'jqys.cn'   //后端给的地址
    // })
}

// 领取抽奖次数
const goGain = (event) => {
  if (!islogined.value) {
    showToast({
      message: "请先登录"
    })
    return
  }
  commonRequest("campaign/award",{id:event.id},"GET").then((res)=>{
    if (res.code==0) {
      showToast({
        message: res.msg
      })
      getInfo()
    }
    else {
      tipType.value = "tip"
      tipContent.value = res.msg
      tipShow.value = !tipShow.value
    }
  })
}

// // 关闭提示组件弹窗
// const closeTip = () => {
//   tipShow.value = !tipShow.value
// }

// 弹出分享弹窗
const share = () => {
  if (!islogined.value) {
    showToast({
      message: "请先登录"
    })
    return
  }
  else {
    tipType.value = "copylink"
    // console.log(window.location.host)
    tipContent.value = "复制您的活动链接，邀请好友为您助力赢取最终大奖！"
    tipLink.value = window.location.origin + "/?invite_code=" + mineInfo.value.invite_code
    tipShow.value = !tipShow.value 
  }
}

const errorImg = (e) => {
    // console.log(e.target.src,"法1")
    // console.log(e.srcElement.src,"法2")
    e.srcElement.src = require('../assets/avatar-error.jpg');
    //这一句没用，如果默认图片的路径错了还是会一直闪屏，在方法的前面加个.once只让它执行一次也没用
    e.srcElement.onerror = null; //防止闪图
}

onMounted(async()=>{
  await utils.checkCsv()
  getInfo()
})

</script>
<style src="./HomeView.scss" lang='scss' scoped></style>
