export const setItem = (key, value) => {
    if ( typeof value === "object" ) {
        JSON.stringify(value)
    }
    window.localStorage.setItem(key,value)
}

export const getItem = (key) => {
    const data = window.localStorage.getItem(key)
    try {
        return JSON.parse(data)
    } catch (e) {
        return data
    }
}

export const removeItem = (key) => {
    window.localStorage.removeItem(key)
}

export const clearItem = () => {
    window.localStorage.clearItem()
}