import { request, csvRequest } from "@/utils/request";
import { setItem } from "@/utils/storage";

export const commonRequest = (url,data = {}, method = "GET") => {
    return new Promise(function (resolve, reject) {
        if ( method === "GET" ) {
            return request({
                method: method,
                url: '/api/v1/' + url,
                params:data
            }).then((res)=> {
                if ( res.status === 200 ) {
                    if (Reflect.has(res.headers,"token")) {
                        setItem("token",res.headers.token)
                    }
                    resolve(res.data)
                }
                else {
                    reject()
                }
            })
        }
        else {
            return request({
                method: method,
                url: '/api/v1/' + url,
                data:data
            }).then((res)=> {
                if ( res.status === 200 ) {
                    if (Reflect.has(res.headers,"token")) {
                        setItem("token",res.headers.token)
                    }
                    resolve(res.data)
                }
                else {
                    reject()
                }
            })
        }
    })
}

export const csvCommonRequest = (url,data = {}, method = "GET") => {
    return new Promise(function (resolve, reject) {
        if ( method === "GET" ) {
            return csvRequest({
                method: method,
                url: '/api/' + url,
                params:data
            }).then((res)=> {
                if ( res.status === 200 ) {
                    resolve(res.data)
                }
                else {
                    reject()
                }
            })
        }
        else {
            return csvRequest({
                method: method,
                url: '/api/' + url,
                data:data
            }).then((res)=> {
                if ( res.status === 200 ) {
                    resolve(res.data)
                }
                else {
                    reject()
                }
            })
        }
    })
}