<template>
    <div class="container">
      <div v-if="!data.loading"
        v-for="(item, index) in data.DrawList._value"
        :key="index"
        class="prize-item"
        @click="() => draw(index)"
      > 
        <div class="prize-item-box" v-if="index!=4">
          <img class="prize-item-img" :src="currentIndex != index ?data.unchoosedImg:data.choosedImg" alt="" />
          <div class="prize" v-if="item.prizeImg!=''">
            <img class="prize-img" :src="item.prizeImg" alt="">
          </div>
          <div class="prize-num" v-if="item.prizeImg!=''">x{{ item.num }}</div>
          <div class="prize" v-else>
            <div class="prize-null">感谢参与</div>
          </div>
          <!-- <p class="desc">{{ item.desc }}</p> -->
        </div>
        <div class="lotter-start-box" v-else>
          <img src="@/assets/btn-start.png" alt="">
          <div>
            <text class="chance-info">剩余抽奖次数:</text>
            <text class="chance-info">{{ lotteryChance }}</text>
          </div>
        </div>
      </div>
      <Tip :content="content" :content2="content2" :show="tipShow" :showBtn=true @close="tipShow=!tipShow" :type="'tip'"></Tip>
    </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue'
import Tip from './TipBox.vue'
 
const tipShow = ref(false)
const content = ref("")
const content2 = ref("")

const props = defineProps({
    loading: {
      default: true
    },
    DrawList: {
      default: []
    },
    lotteryChance: {
      default: 0
    },
    choosedImg: {
      default: ""
    },
    unchoosedImg: {
      default: ""
    }
})
const data = ref({
    loading: true,
    DrawList: [],
    lotteryChance: 0,
    choosedImg: "",
    unchoosedImg: ""
})
watch(props,(value)=>{
  // data.value.loading = value.loading
  // data.value.DrawList = value.DrawList
  // data.value.lotteryChance = value.lotteryChance
  // data.value.choosedImg = value.choosedImg
  // data.value.unchoosedImg = value.unchoosedImg
  data.value = value
})

const ctx = defineEmits(["goDraw"])
const drawOrder = [0, 1, 2, 5, 8, 7, 6, 3] // 抽奖顺序
let count = 0 // 抽奖次数
let isDrawing = false // 是否正在抽奖
const currentIndex = ref() // 当前选中的奖品
const circle = 32 // 一圈8个奖品，至少转4圈

const draw = (index) => {
  let prizeIndex
  if (isDrawing) {
    return
  }
  else if (props.lotteryChance<=0) {
    return
  }
  if (index === 4) {
    isDrawing = true // 这里要在请求开始时就限制掉
    ctx("goDraw",(res)=>{
      if (res.code == 0) {
        switch (res.index) {
          case 0:
            prizeIndex = 0
            break;
          case 1:
            prizeIndex = 1
            break;
          case 2:
            prizeIndex = 2
            break;
          case 3:
            prizeIndex = 7
            break;
          case 4:
            prizeIndex = 3
            break;
          case 5:
            prizeIndex = 6
            break;
          case 6:
            prizeIndex = 5
            break;
          case 7:
            prizeIndex = 4
            break;
          default:
            break;
        }
        
        const timer = setInterval(() => {
          currentIndex.value = drawOrder[count % drawOrder.length]
          count++
          if (count > circle && currentIndex.value === drawOrder[prizeIndex]) {
            // 抽奖结束
            clearInterval(timer)
            // 停顿一会显示中奖
            setTimeout(() => {
              tipShow.value = true
              if (currentIndex.value==0) {
                content.value = "感谢参与"
                content2.value = ""
              } else {
                content.value = "恭喜您获得:"+data.value.DrawList._value[currentIndex.value]["desc"]+"X"+data.value.DrawList._value[currentIndex.value]["num"]+" ,"
                content2.value = "已发送至游戏内邮箱"
              }
              isDrawing = false
              count = 0
              currentIndex.value = null
            }, 500)
          }
        }, 100)
      }
      else {
        tipShow.value = true
        content.value = res.msg
        isDrawing = false
        // console.log("isEnable",isEnable)
      }
      // prizeIndex = res.index
    })
  }
}

onMounted(()=>{
  data.value = props
})

</script>
<style src="./LotteryBox.scss" lang='scss' scoped></style>