const serverIndex = 0
const serverList = [
    // 'https://jqys-player-market.racoondigi.cn',
    // 'https://wxpub-test.racoondigi.cn',
    // 'http://192.168.1.154:8089'
    'https://activity.jqys.cn',
    'http://106.15.57.192:5555',
    'http://192.168.1.149:8082'
]

const csvServerList = [
    "https://jqys2-proxy-ali.racoondigi.cn:8090",
    "http://jqys2-test.racoondigi.cn:8089",
    "http://jqys2-test.racoondigi.cn:8089"
]

export default {
    serverIp: serverList[serverIndex],
    csvServerIp: csvServerList[serverIndex]
}
